<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title mb-0">ADD INFLUENCER</p>
    </div>
    <div class="dialog-content">
      <b-container fluid>
        <b-form @submit="onSubmit" v-if="custom.status === 'initial'">
          <b-form-group label="First Name"
                        :label-cols="2"
                        label-for="firstname"
                        horizontal>
            <b-form-input id="firstname"
                          type="text"
                          aria-describedby="firstname-input"
                          placeholder="Enter First Name"
                          @input="$v.form.firstname.$touch()"
                          v-model.trim="$v.form.firstname.$model"
                          :state="!$v.form.firstname.$error">
            </b-form-input>
            <b-form-invalid-feedback id="firstname-input">
              First Name is required.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Last Name"
                        :label-cols="2"
                        label-for="lastname"
                        horizontal>
            <b-form-input id="lastname"
                          type="text"
                          aria-describedby="lastname-input"
                          placeholder="Enter Last Name"
                          @input="$v.form.lastname.$touch()"
                          v-model.trim="$v.form.lastname.$model"
                          :state="!$v.form.lastname.$error">
            </b-form-input>
            <b-form-invalid-feedback id="lastname-input">
              Last Name is required.
            </b-form-invalid-feedback>
          </b-form-group>


          <b-form-group label="Nickname"
                        :label-cols="2"
                        label-for="nickname"
                        horizontal>
            <b-form-input id="nickname"
                          type="text"
                          aria-describedby="nickname-input"
                          placeholder="Enter Nickname"
                          @input="$v.form.nickname.$touch()"
                          v-model.trim="$v.form.nickname.$model"
                          :state="!$v.form.nickname.$error">
            </b-form-input>
            <b-form-invalid-feedback id="nickname-input">
              Nickname is required.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Email"
                        :label-cols="2"
                        label-for="email"
                        horizontal>
            <b-form-input id="email"
                          type="email"
                          aria-describedby="email-input"
                          placeholder="Enter Email"
                          @input="$v.form.email.$touch()"
                          v-model.trim="$v.form.email.$model"
                          :state="!$v.form.email.$error">
            </b-form-input>
            <b-form-invalid-feedback id="email-input">
              <span v-if="!$v.form.email.required">
                Email is required.
              </span>
              <span v-if="!$v.form.email.email && $v.form.email.required">
                This requires a valid email address.
              </span>
              <span v-if="!$v.form.email.uniqueEmail && $v.form.email.email &&
                      $v.form.email.required">
                Email is already registered.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button class="text-center"
                      type="submit"
                      :disabled="$v.$invalid"
                      variant="outline-primary"
                      size="sm">
              Submit
            </b-button>
            <b-button class="text-center ml-1 "
                      variant="outline-danger"
                      @click="$emit('close')"
                      size="sm">
              Cancel
            </b-button>
          </div>
        </b-form>

        <div v-else>
          <b-row class="justify-content-md-center" style="padding: 3rem 0;">
            <b-col cols="12" md="auto">
              <span class='fa-stack fa-4x reg_violet'>
                <i class='fa fa-circle fa-stack-2x current-step'></i>
                <i class='fa fa-envelope fa-stack-1x fa-inverse'></i>
              </span>
            </b-col>
          </b-row>
          <b-row class="justify-content-md-center">
            <b-col cols="12" md="auto">
              <p class='message medium text-center'
                 v-html="custom.message"></p>
              <div class="d-flex justify-content-center"
                   v-if="custom.status === 'failed'">
                <b-button class="text-center"
                          type="submit"
                          variant="outline-primary"
                          @click="sendActivationEmail">
                  Resend Activation Email
                </b-button>
                <b-button class="text-center ml-1 "
                          variant="outline-danger"
                          @click="closeModal">
                  Cancel
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>

      <!-- Loading -->
      <loading :active.sync="isLoading"
               :is-full-page="true"
               background-color="#000"
               color="#068bcd"
               :opacity="0.5"
               :width="70"
               :height="70">
      </loading>
    </div>
  </div>
</template>

<script>
  const Loading = () => import('vue-loading-overlay');

  import md5 from 'md5';
  import { required, email } from 'vuelidate/lib/validators';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name : 'AddInfluencer',
    data() {
      return {
        form : {
          firstname : null,
          lastname  : null,
          nickname  : null,
          email     : null,
          password  : 'healthyhiphop',
          userType  : 5,
        },
        uniqueEmail : true,
        userID      : null,
        isLoading   : false,
        custom      : {
          status  : 'initial',
          message : null,
        },
      }
    },
    validations() {
      return {
        form : {
          firstname : { required },
          lastname  : { required },
          nickname  : { required },
          email     : {
            email,
            required,
            uniqueEmail() {
              return this.uniqueEmail;
            },
          },
        },
      }
    },
    computed : {
      formEmail() {
        return this.form.email;
      },
    },
    components : {
      Loading,
    },
    watch : {
      formEmail(value) {
        this.uniqueEmail = true;
        if (value)
          this.checkEmail();
      },
    },
    methods : {

      /**
       * Check if Email is already registered
       */
      // eslint-disable-next-line no-undef
      checkEmail : _.debounce(function () {
        this.$http.get('api/user/email', {
          params : {
            email : this.form.email,
          },
        }).then(response => {
          // swapped value since api returns true
          //  if email exists hence email is not unique
          this.uniqueEmail = (response.data) ? false : true;
        }).catch(() => {});
      }, 500),

      /**
       * Add User
       */
      onSubmit(e) {
        e.preventDefault();
        this.isLoading = true;
        this.$http.post('api/user', {
          'firstname' : this.form.firstname,
          'lastname'  : this.form.lastname,
          'nickname'  : this.form.nickname,
          'email'     : this.form.email,
          'password'  : this.form.password,
          'userType'  : this.form.userType,
        }).then(() => {
          // Get user id via email
          this.getUserID(this.form.email);

          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Added ' + this.form.firstname + ' succesfully!',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Get User ID via Email
       * @param {string} email User's registered email address
       */
      getUserID(email) {
        this.$http.get('api/user/id', {
          params : {
            email : email,
          },
        }).then(response => {
          this.userID = response.data.user_id;

          // Send email
          this.sendActivationEmail();
        });
      },

      /**
       * Send Activation Email to User
       */
      sendActivationEmail() {
        this.custom.status = 'sending';
        this.custom.message = 'Sending an Activation Email to ' +
          this.form.firstname + ' ' + this.form.lastname;
        
        const baseUrl = process.env.VUE_APP_BASE_URL;
        const activationUrl = baseUrl + '/activate/' + this.userID + '/' +
          md5(this.form.email);
        
        this.$http.post('auth/sendActivationEmail', {
          'to'   : this.form.email,
          'url'  : activationUrl,
          'name' : this.form.firstname,
        }).then(() => {
          // Successfuly Sent an Email Verification
          this.custom.message = 'Activation Email Sent Successfully!';

          const that = this;
          setTimeout(() => {
            that.isLoading = false;
            that.$emit('close');
            that.custom.status = 'success';
            this.$parent.$emit('add-influencer-successful');
          }, 2000);
        }).catch(() => {
          this.custom.status = 'failed';
          this.custom.message = 'Failed to Send an Activation Email! <br>';
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/influencers/add-influencer";
</style>